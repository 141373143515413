@import './../sass/reuse';

.featuredHomepageBlock {
  text-transform: uppercase;
  position: relative;
  padding: $gap;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  h1 {
    color: $white;
  }

  @include tablet {
    padding: 0 $gap 0 116px;
  }

  @include mobile {
    padding: 0 0 0 62px;

    .subTitle {
      font-size: 12px;
    }
  }
}

.featuredHomepageBlockContainer {
  flex: 0 0 100%;
}

.imageContainer {
  position: absolute;
  background-color: $black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  z-index: -1;

  @include desktop {
    border-radius: 10px;
  }
}

.featuredHomepageBlock,
.imageContainer {
  height: calc(100vh - #{$header-height});

  @include tablet {
    height: 442px;
  }

  @include mobile {
    height: 180px;
  }
}

.swiper {
  background-color: $body-background-color;
}
